const wrapS3URL = (glimpseName: string) => {
  return `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/${glimpseName}.jpg`
}

export const gallery = [
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/chef_deepak_gond.png',
    width: 5,
    height: 3,
    alt: 'Book a chef online for birthday house party via COOX',
  },
  {
    src: wrapS3URL('glimpse9'),
    width: 3,
    height: 4,
    alt: 'Continental Chef cooking at home kitchen in Gurgaon via COOX.in',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/3H.jpeg',
    width: 3,
    height: 4,
    alt: 'Amazing Plating of Malai Chicken Tikka by North Indian Chef of COOX',
  },
  {
    src: wrapS3URL('glimpse1'),
    width: 4,
    height: 4,
    alt: 'Tandoor chefs at barbeque party in Noida via COOX',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/7H.jpg',
    width: 4,
    height: 3,
    alt: 'Hire a chef online for Kitty Party at home via COOX.in',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/6H.jpeg',
    width: 3,
    height: 4,
    alt: 'North Indian and Chinese food cooked at home by COOX',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/5H.jpg',
    width: 3,
    height: 4,
    alt: 'Amazing Plating of Tandoori Stuffed Potato dish by North Indian Chef of COOX',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/8H.jpg',
    width: 3,
    height: 4,
    alt: 'Chef at home by COOX',
  },
  {
    src: wrapS3URL('glimpse10'),
    width: 6,
    height: 4,
    alt: 'Wada prepared by South Indian Chef in Faridabad by COOX.in',
  },
  {
    src: wrapS3URL('glimpse7'),
    width: 0,
    height: 4,
    alt: 'Delicious Fruit Cream Dessert prepared by Chefs from COOX',
  },
]

export const bartenderGallery = [
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/bartender/hard_drinks_and_soft_drinks.jpg',
    width: 3,
    height: 4,
    alt: 'Hard drinks and Soft drinks',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/bartender/bartender_for_parties.jpeg',
    width: 5,
    height: 3,
    alt: 'Bartender for parties',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/bartender/drinks_and_beverages.jpg',
    width: 5,
    height: 3,
    alt: 'Drinks and Beverages',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/bartender/cocktails_and_mocktails.jpg',
    width: 3,
    height: 4,
    alt: 'Cocktails and Mocktails',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/bartender/bartenders_at_home.jpg',
    width: 5,
    height: 4,
    alt: 'Bartenders at home',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/bartender/bartender_service_near_me.jpg',
    width: 5,
    height: 3,
    alt: 'Bartender service near me',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/bartender/bartender_service_for_events.jpg',
    width: 4,
    height: 3,
    alt: 'Bartender service for events',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/bartender/bartender_for_house_party.jpeg',
    width: 4,
    height: 4,
    alt: 'Bartender for house party',
  },
]

export const waiterGallery = [
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/glimpse27.jpg',
    width: 3,
    height: 4,
    alt: 'Hard drinks and Soft drinks',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/glimpse28.jpg',
    width: 5,
    height: 3,
    alt: 'Bartender for parties',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/glimpse23.jpg',
    width: 3,
    height: 3,
    alt: 'Drinks and Beverages',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/glimpse21.jpg',
    width: 3,
    height: 4,
    alt: 'Cocktails and Mocktails',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/glimpse25.jpg',
    width: 3,
    height: 4,
    alt: 'Bartenders at home',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/waiter4.jpeg',
    width: 5,
    height: 4,
    alt: 'Bartenders at home',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/waiter3.jpeg',
    width: 4,
    height: 4,
    alt: 'Bartenders at home',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/waiter2.jpeg',
    width: 4,
    height: 4,
    alt: 'Bartenders at home',
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/waiter1.jpeg',
    width: 4,
    height: 4,
    alt: 'Bartenders at home',
  },
]
