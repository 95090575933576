// BOOTSTRAP
import { Col, Container, Row } from 'react-bootstrap'

// CSS
import styles from './index.module.css'

// REACT PHOTO GALLERY
import Gallery from 'react-photo-gallery'

// STATIC DATA
import {
  bartenderGallery,
  gallery,
  waiterGallery,
} from '@/staticData/homepage-gallery.data'

// NEXT
import Link from 'next/link'

const HomepageGallery = (props): JSX.Element => {
  return (
    <Container>
      <h2 className="text-center mt-5 DARK__GOLDEN__COLOR">Gallery</h2>
      <p className={`text-muted text-center mb-4 ${styles.font__size__14}`}>
        <Link href="/gallery">
          <a target="_blank">See</a>
        </Link>{' '}
        {props?.level === 'bartender'
          ? 'some bartenders sizzle'
          : props?.level === 'cleaner'
          ? 'cleaners in action'
          : props?.level === 'waiter'
          ? 'waiters in action'
          : 'some chefs sizzle'}
      </p>

      <br />
      <Gallery
        photos={
          props.level === 'bartender'
            ? bartenderGallery
            : props.level === 'waiter'
            ? waiterGallery
            : gallery
        }
      />
      <br />
      <br />

      <Row>
        <Col className="text-center">
          <Link href="/gallery">
            <a className={`btn ${styles.learn__more__btn}`} target="_blank">
              View More
            </a>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default HomepageGallery
