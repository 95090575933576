import Image from 'next/image'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap'

// TS INTERFACES
interface Props {
  image: string
  name: string
  onClick: () => void
  alt: string
}

const shimmer = (
  w,
  h
) => `<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#edeef1" offset="20%" />
      <stop stop-color="#f6f7f8" offset="50%" />
      <stop stop-color="#f6f7f8" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#f6f7f8" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

const toBase64 = (str) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str)

const HomePageGridComponent = (props: Props): JSX.Element => {
  const { image, name, onClick, alt } = props
  return (
    <Col
      lg={4}
      md={4}
      xs={4}
      className={`text-center ${styles.cursor__pointer}`}
      onClick={onClick}
    >
      <Row>
        <Col className={`p-2`}>
          <div className={styles.img}>
            {image && (
              <Image
                placeholder="blur"
                blurDataURL={`data:image/svg+xml;base64,${toBase64(
                  shimmer(144, 192)
                )}`}
                src={image}
                alt={alt}
                className={styles.image}
                width={300}
                height={150}
                objectFit="cover"
                // unoptimized={true}
              />
            )}

            <p
              className={`text-muted font-weight-bold my-3 ${styles.name__font__size}`}
            >
              {name}
            </p>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <p
            className={`text-muted font-weight-bold my-3 ${styles.name__font__size}`}
          >
            {name}
          </p>
        </Col>
      </Row> */}
    </Col>
  )
}

export default HomePageGridComponent
