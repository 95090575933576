// Styles
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap'

const GridLoader = (): JSX.Element => {
  return  (
    <Row>
      {[...Array(6).keys()].map((item) => (
        <Col lg={4} md={4} xs={4} className={`p-0 ${styles.align__center}`} key={item}>
          <div className={styles.image__skeleton} />
          <div className={styles.name__skeleton} />
        </Col>
      ))}
    </Row>
  )
}

export default GridLoader
