import Image from 'next/image'
import Link from 'next/link'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Col, Card } from 'react-bootstrap'

// REACT ICONS
import { FaExternalLinkAlt } from 'react-icons/fa'

// HELPER
import { ratingJSX } from '../helper'

// TS INTERFACES
import { Icook } from '@/types/cookTypes'

const CookCardItem = (props: Icook): JSX.Element => {
  const { name, profile_img, cuisine, level, rating, profile_name, published } =
    props
  let profileUrl = ''
  if (level === 'Chef') {
    profileUrl = `/cook/${profile_name}`
  } else if (level === 'Bartender') {
    profileUrl = `/bartender/${profile_name}`
  } else if (level === 'Waiter') {
    profileUrl = `/waiter/${profile_name}`
  } else if (level === 'Cleaner') {
    profileUrl = `/cleaner/${profile_name}`
  }
  return (
    <div className="slider-container swiper-slide mt-3 mb-3">
      <Card className={`mb-3 ${styles.cookCard}`}>
        <div className="rounded-circle text-center mt-3 ">
          {/* <Image
            src={profile_img}
            alt={`Top rated Cook - ${name}`}
            width={125}
            height={125}
            objectFit="cover"
            className="homepage__cook__profile__photo"
          /> */}
          <Link href={profileUrl}>
            <a target="_blank" rel="noopener noreferrer">
              <Image
                src={profile_img}
                alt={`COOX best rated cooks chefs with highest ratings, reviews, feedback. Hire expert trending cooks for small house parties, private parties`}
                width={100}
                height={100}
                // objectFit="cover"
                style={{ objectFit: 'cover' }}
                className={`homepage__cook__profile__photo ${styles.roundedCircle}`}
                // unoptimized={true}
              />
            </a>
          </Link>
        </div>
        <Card.Body>
          <Card.Title>
            <Row>
              <Col className="text-center">
                <h3
                  className={`DARK__GOLDEN__COLOR ${styles.cookName} ${styles.font__size__24}`}
                >
                  {published ? (
                    <Link href={profileUrl}>
                      <a
                        className="DARK__GOLDEN__COLOR"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {name} <br />
                        <FaExternalLinkAlt
                          className={`mb-1 ${styles.font__size__12}`}
                        />
                      </a>
                    </Link>
                  ) : (
                    name
                  )}
                </h3>
              </Col>
            </Row>

            <Row>
              <Col className="text-center">
                <p className={`text-muted ${styles.font__size__14} mb-3`}>
                  {cuisine} {level}
                </p>
              </Col>
            </Row>

            <Row>
              <Col className="text-center">{ratingJSX(rating ?? 4)}</Col>
            </Row>
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  )
}

export default CookCardItem
