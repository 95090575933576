import axios from 'axios'

// TS INTERFACES

// ================================================================
// ================================================================

export const fetchOccasions = (): Promise<any> =>
  axios.get(`/occasions?without_meta=true`)

// ================================================================
// ================================================================

export const fetchCombos = (): Promise<any> =>
  axios.get(`/cuisines/combos`).then((res) => res.data.combos)

// ================================================================
// ================================================================

export const fetchCuisines = (): Promise<any> => axios.get(`/cuisines`)

// ================================================================
// ================================================================

export const fetchTopRatedCooks = (): Promise<any> =>
  axios.get(`/cook/top-rated-cooks?_limit=3`)

// ================================================================
// ================================================================
