import styles from './index.module.css'

export const ratingJSX = (rating: number): JSX.Element => {
  let bgColor = 'green'
  let textColor = 'white'
  if (rating <= 3) {
    bgColor = '#4CD137'
  }
  if (rating <= 2) {
    bgColor = '#FFC107'
    textColor = 'black'
  }
  if (rating <= 1) {
    bgColor = 'red'
  }
  return (
    <p
      className="d-inline font-weight-bolder px-3 py-1 rounded"
      style={{ backgroundColor: bgColor, color: textColor, fontSize: '1rem' }}
    >
      {rating?.toFixed(1)}
    </p>
  )
}