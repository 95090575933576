interface Iservices {
  id: number
  image: string
  name: string
  alt: string
  path: string
  type: string
}
export const popularServices: Array<Iservices> = [
  {
    id: 1,
    image:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/popular_service_chef.png',
    name: 'Cooks & Chefs',
    alt: 'Book trained professional cooks chefs at home. Best personal chef services via COOX. Hire top rated cooks chefs near me',
    path: '/cook?action=book-a-chef',
    type: 'cook',
  },
  {
    id: 2,
    image:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/popular_service_Bartender.png',
    name: 'Bartenders for Parties',
    alt: 'Book trained professional cooks chefs at home. Best personal chef services via COOX. Hire top rated cooks chefs near me',
    path: '/bartender?action=book-a-bartender',
    type: 'bartender',
  },
  {
    id: 3,
    image:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/popular_service_Waiter.png',
    name: 'Waiters for Events',
    alt: 'Book trained professional cooks chefs at home. Best personal chef services via COOX. Hire top rated cooks chefs near me',
    path: '/waiter?action=book-a-waiter',
    type: 'waiter',
  },
  {
    id: 4,
    image:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/popular_service_cleaner.png',
    name: 'Cleaners at Home',
    alt: 'Book trained professional cooks chefs at home. Best personal chef services via COOX. Hire top rated cooks chefs near me',
    path: '/cleaner?action=book-a-cleaner',
    type: 'cleaner',
  },
]
