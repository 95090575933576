const wrapS3URLForCity = (cityImageName: string) => {
  return `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/cities/150x75/${cityImageName}`
}

export const weAreInCityData = [
  {
    name: 'Delhi',
    slug: 'delhi',
    imageSrc: wrapS3URLForCity('delhi.jpg'),
  },
  {
    name: 'Noida',
    slug: 'noida',
    imageSrc: wrapS3URLForCity('noida.jpg'),
  },
  {
    name: 'Gurugram',
    slug: 'gurugram',
    imageSrc: wrapS3URLForCity('gurgaon.jpg'),
  },
  {
    name: 'Faridabad',
    slug: 'faridabad',
    imageSrc: wrapS3URLForCity('faridabad.jpg'),
  },
  {
    name: 'Ghaziabad',
    slug: 'ghaziabad',
    imageSrc: wrapS3URLForCity('ghaziabad.jpg'),
  },
  {
    name: 'Greater Noida',
    slug: 'greater-noida',
    imageSrc: wrapS3URLForCity('greaterNoida.jpg'),
  },
  {
    name: 'Bengaluru',
    slug: 'bengaluru',
    imageSrc:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/city/Bengaluru.jpeg',
  },
  {
    name: 'Hyderabad',
    slug: 'hyderabad',
    imageSrc:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/city/Hyderabad.jpeg',
  },
  {
    name: 'Mumbai',
    slug: 'mumbai',
    imageSrc:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/city/Mumbai.jpeg',
  },
  {
    name: 'Jaipur',
    slug: 'jaipur',
    imageSrc:
      'https://coox-new.s3.ap-south-1.amazonaws.com/images/city//1f6cf4ea-c25b-4661-8d6c-f62e57741acc-Jaipur.jpeg',
  },
  {
    name: 'Ahmedabad',
    slug: 'ahmedabad',
    imageSrc:
      'https://coox-new.s3.ap-south-1.amazonaws.com/images/city//392debab-614e-4047-8528-79504f235a73-Ahmedabad.jpeg',
  },
  {
    name: 'Kolkata',
    slug: 'kolkata',
    imageSrc:
      'https://coox-new.s3.ap-south-1.amazonaws.com/images/city//ba7b9129-3cb3-4ab5-9902-41e309a3cf4e-Kolkata.jpeg',
  },
  {
    name: 'Chandigarh',
    slug: 'chandigarh',
    imageSrc:
      'https://coox-new.s3.ap-south-1.amazonaws.com/images/city//804bd852-e05b-4dfe-b2cf-2f2f0d0c1a5d-Chandigarh.jpeg',
  },
  {
    name: 'Ludhiana',
    slug: 'ludhiana',
    imageSrc:
      'https://coox-new.s3.ap-south-1.amazonaws.com/images/city//e60687c2-6a98-4b06-9f65-66d42af0f2d3-Ludhiana.jpeg',
  },
  {
    name: 'Pune',
    slug: 'pune',
    imageSrc:
      'https://coox-new.s3.ap-south-1.amazonaws.com/images/city//2417ab7c-055d-47bd-8dfd-101cffc3801e-Pune.jpeg',
  },
  {
    name: 'Goa',
    slug: 'goa',
    imageSrc:
      'https://coox-new.s3.ap-south-1.amazonaws.com/images/city//29b3da5d-b63b-4a40-8c46-e52a35a6dcad-Goa.jpeg',
  },
  {
    name: 'Surat',
    slug: 'surat',
    imageSrc:
      'https://coox-new.s3.ap-south-1.amazonaws.com/images/city//437449f7-bf0d-468e-8a22-725201aa1361-Surat.jpeg',
  },
  {
    name: 'Chennai',
    slug: 'chennai',
    imageSrc:
      'https://coox-new.s3.ap-south-1.amazonaws.com/images/city//e0c7a112-5ef9-461b-ba62-72a868316a34-Chennai.jpeg',
  },
]
