// BOOTSTRAP
import { Row, Container } from 'react-bootstrap'

// COMPONENTS
import HomePageGridComponent from './HomePageGridItem'
import GridLoader from './GridLoader/index'

// STATIC DATA
import { popularServices } from '@/staticData/homepage-popular-services.data'

// TS INTERFACES
interface Props {
  heading: string
  loading: boolean
  subHeadingJSX?: JSX.Element
  hide: string
}

const PeopleAlsoBooked = (props: Props): JSX.Element => {
  const {
    heading,
    loading,
    subHeadingJSX,
    hide,
  } = props
  return (
    <Container>
      <h2 className={`text-center mt-5 DARK__GOLDEN__COLOR`}>{heading}</h2>
      {subHeadingJSX}

      {loading && <GridLoader />}
      <Row>
        {popularServices
          ?.filter((item) => item.type != hide)
          .map((item) => {
            const { id, name, image, path, alt } = item

            return (
              <HomePageGridComponent
                key={id}
                name={name}
                image={image}
                alt={alt}
                path={path}
              />
            )
          })}
      </Row>
    </Container>
  )
}

export default PeopleAlsoBooked
