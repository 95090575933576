interface ReviewType {
  tagline: string
  customerImgName: string
  alt: string
  comment: string
  customerName: string
  date: string
}

export const reviews: ReviewType[] = []

export const chefReviews: ReviewType[] = [
  {
    tagline: 'On Time',
    customerImgName: 'images/reviews/Nivedita_Bhide.jpeg',
    alt: 'Nivedita Bhide',
    comment:
      'Everything was fantastic, chef came before time and made our party amazing. Will book again soon.',
    customerName: 'Nivedita Bhide',
    date: '19 Aug 2022',
  },
  {
    tagline: 'Good Presentation',
    customerImgName: 'images/reviews/Rohit_Kumar.jpeg',
    alt: 'Rohit Kumar',
    comment:
      'Chef was a very warm person. Awesome plating, garnishing and apt quantity of all dishes.',
    customerName: 'Rohit Kumar',
    date: '30 Jul 2022',
  },
  {
    tagline: 'Thorough Professionals',
    customerImgName: 'images/reviews/Saurabh_Srivastava.jpeg',
    alt: 'Saurabh Shrivastava',
    comment:
      "COOX made our life easy. We had 30 guests at home and I didn't have to worry or even step inside the kitchen.",
    customerName: 'Saurabh Shrivastava',
    date: '7 Jun 2022',
  },
  {
    tagline: 'Easy To Book',
    customerImgName: 'images/reviews/Apoorva_Madan.jpeg',
    alt: 'Apoorva Madan',
    comment:
      'The chef took care of all our requests. Everything was smooth from booking to cooking.',
    customerName: 'Apoorva Madan',
    date: '17 May 2022',
  },
  {
    tagline: 'Proper Hygiene',
    customerImgName: 'images/reviews/Surinder_Anand.jpeg',
    alt: 'Surinder Anand',
    comment:
      'Great service. Chef was very cooperative. Did a decent job in cleaning up the kitchen in the end.',
    customerName: 'Surinder Anand',
    date: '20 Apr 2022',
  },
  {
    tagline: 'Mouthwatering Food',
    customerImgName: 'images/reviews/Sagar_Matta.jpeg',
    alt: 'Sagar Matta',
    comment:
      'The chef was so nice and the food was actually very tasty. Best Shahi Paneer ever!',
    customerName: 'Sagar Matta',
    date: '3 Mar 2022',
  },
  {
    tagline: 'Guests Loved It',
    customerImgName: 'images/reviews/Brijendra_Singh.jpeg',
    alt: 'Brijendra Singh',
    comment:
      'We had booked them for a pooja at home. Our guests were blown over. Thank you so much!',
    customerName: 'Brijendra Singh',
    date: '23 Feb 2022',
  },
  {
    tagline: 'Highly Recommended',
    customerImgName: 'images/reviews/review_2.JPG',
    alt: 'Five star reviews from customers',
    comment:
      'Food quantity, presentation and taste was on point. All guests were overwhelmed. Ingredients quantity was perfect. Absolutely brilliant!',
    customerName: 'Anuj Agarwal',
    date: '29 Mar 2021',
  },
  {
    tagline: 'Amazing Experience',
    customerImgName: 'images/reviews/review_1.JPG',
    alt: 'Five star reviews from customers',
    comment:
      'Hired a Chef for our party and it was the best decision ever during these pandemic times. So professional. Everyone must try them for sure. Great job, COOX.',
    customerName: 'Himika Khurana',
    date: '15 Oct 2020',
  },
  {
    tagline: 'A Unique Concept',
    customerImgName: 'images/website/testimonials/users/review2.jpg',
    alt: 'Five star reviews from customers',
    comment:
      'So convenient! I was relaxed and could easily spend time with my friends. No kitchen worries. The chefs professionally handled my kitchen.',
    customerName: 'Shruti Malhotra',
    date: '08 Sep 2019',
  },
]

export const bartenderReviews: ReviewType[] = [
  {
    tagline: 'Highly Recommended',
    customerImgName: 'images/reviews/Gopal_Kumar.jpeg',
    alt: 'Gopal Kumar',
    comment:
      "The bartender was very efficient and handled my party with ease. Wouldn't hesitate to recommend.",
    customerName: 'Gopal Kumar',
    date: '5 Aug 2022',
  },
  {
    tagline: 'Professional Mixologists',
    customerImgName: 'images/reviews/Neha_Kalia.jpeg',
    alt: 'Neha Kalia',
    comment:
      'Made some really nice cocktails. Salt Rim Margaritas were my favourite! Keep it up!',
    customerName: 'Neha Kalia',
    date: '18 Jul 2022',
  },
  {
    tagline: 'Late Night Service',
    customerImgName: 'images/reviews/Anushil_Mathur.jpeg',
    alt: 'Anuhsil Mathur',
    comment:
      'Hiring their bartender for my party was the best decision. Stayed till late night 3am. Thanks.',
    customerName: 'Anuhsil Mathur',
    date: '23 May 2022',
  },
]

export const waiterReviews: ReviewType[] = [
  {
    tagline: 'Well Trained Staff',
    customerImgName: 'images/reviews/Shivam_Singhal.jpeg',
    alt: 'Shivam Singhal',
    comment:
      'Both waiters were pleasing and cooperative. Thank you for making our event special.',
    customerName: 'Shivam Singhal',
    date: '11 Aug 2022',
  },
  {
    tagline: 'Pro Active',
    customerImgName: 'images/reviews/Kansihk_Bajpai.jpeg',
    alt: 'Kanishk Bajpai',
    comment:
      'Happy with overall service. He managed the food and drinks serving pretty well. Didn’t have to worry as hosts.',
    customerName: 'Kanishk Bajpai',
    date: '15 Jul 2022',
  },
  {
    tagline: 'Timely Service',
    customerImgName: 'images/reviews/Rhythm_Gupta.jpeg',
    alt: 'Rhythm Gupta',
    comment:
      'Excellent! Waiter was very polite and served with style. Reached on time also.',
    customerName: 'Rhythm Gupta',
    date: '1 Mar 2022',
  },
]

export const cleanerReviews: ReviewType[] = [
  {
    tagline: 'Spick & Span',
    customerImgName: 'images/reviews/Shruti_Malik.jpeg',
    alt: 'Shruti Malik',
    comment:
      'He cleaned my kitchen so nicely. Kept everything back in place properly after wiping. Was so careful with my cutlery.',
    customerName: 'Shruti Malik',
    date: '6 Aug 2022',
  },
  {
    tagline: 'Extremely Helpful',
    customerImgName: 'images/reviews/Vinod_Kumar.jpeg',
    alt: 'Vinod Kumar',
    comment:
      'The cleaner was very hard working. Washed every single utensil and appliance. Tipped him well.',
    customerName: 'Vinod Kumar',
    date: '11 May 2022',
  },
  {
    tagline: 'Reliable Service',
    customerImgName: 'images/reviews/Pranav_Verma.jpeg',
    alt: 'Pranav Verma',
    comment:
      'Never knew I could book cleaners for house party at such reasonable prices. Much better than my maids & domestic helpers.',
    customerName: 'Pranav Verma',
    date: '29 Jan 2022',
  },
]
