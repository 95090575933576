// COMPONENTS
import { Row, Col } from 'react-bootstrap'

import { useDispatch } from 'react-redux'

import styles from './index.module.css'

import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import bookingCleanerModalSlice from '@/lib/slices/bookingCleanerModalSlice'
import bookingWaiterModalSlice from '@/lib/slices/bookingWaiterModalSlice'

const ExperienceCOOXCTA = (props): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <>
      <br />
      <br />

      <Row className={`py-5 text-center ${styles.dropanote__strip}`}>
        <Col>
          <b className={`text-center FONT__SIZE__14 ${styles.dropanote__item}`}>
            Experience COOX.{' '}
            <span
              className={styles.clickable}
              onClick={() => {
                if(props?.setAskServicesModal){
                  props.setAskServicesModal(true)
                }else{
                  if (props?.level === 'bartender')
                    dispatch(
                      bookingBartenderModalSlice.actions.toggleBookingBartenderModal()
                    )
                  else if (props?.level === 'cleaner')
                    dispatch(
                      bookingCleanerModalSlice.actions.toggleBookingCleanerModal()
                    )
                  else if (props?.level === 'waiter')
                    dispatch(
                      bookingWaiterModalSlice.actions.toggleBookingWaiterModal()
                    )
                  else dispatch(bookingModalSlice.actions.toggleBookingModal())
                }
              }}
            >
              Book Now!
            </span>
          </b>
        </Col>
      </Row>
      <br />
      <br />
    </>
  )
}

export default ExperienceCOOXCTA
