import React, { useRef } from 'react'
import Link from 'next/link'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Container, Col } from 'react-bootstrap'

// 3rd party libraries
import Swiper from 'react-id-swiper'
import SwiperCore, { Autoplay } from 'swiper'
SwiperCore.use([Autoplay])

import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'

// REACT QUERY
// import { useQuery } from 'react-query'

// FETCHERS
// import { fetchTopRatedCooks } from '@/fetchers/apiDataFetchers'

// Import Swiper React components
// Import Swiper styles
import 'swiper/swiper.min.css' // react-id-swiper CSS
import 'swiper/swiper-bundle.min.css'

// COMPONENTS
import CookCardItem from './CookCardItem'

// TS INTERFACES
import { Icook } from '@/types/cookTypes'

interface Props {
  topCooks: Icook[]
}

interface Swiper {
  swiper: any
}

const TopRatedChefs = (props: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement & Swiper>(null)

  const { topCooks } = props

  const cooks = []
  for (const cook of topCooks) {
    if (cook.profile_img && cook.name && cook.profile_name && cook.rating) {
      cooks.push({
        url: cook.profile_img,
        caption: cook.name,
        name: cook.profile_name,
        rating: cook.rating,
        // link: `/hotels-restaurants/${hotel.slug}`,
      })
    }
  }

  const goNext = () => {
    const swiperRef = ref.current
    if (swiperRef !== null && swiperRef.swiper !== null) {
      swiperRef.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev()
    }
  }

  const params = {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2.3,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2.3,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3.5,
        spaceBetween: 20,
      },
      1080: {
        slidesPerView: 4.5,
        spaceBetween: 20,
      },
    },

    loop: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  }

  return (
    <Container>
      <h2 className={`text-center mt-5 DARK__GOLDEN__COLOR`}>
        {props?.level === 'Bartender'
          ? 'Top Rated Bartenders'
          : props?.level === 'Waiter'
          ? 'Top Rated Waiters'
          : props?.level === 'Cleaner'
          ? 'Top Rated Cleaners'
          : props?.heading
          ? props?.heading
          : 'Top Rated Chefs'}
      </h2>
      {props?.heading ? (
        <p className={`text-muted text-center mb-4 ${styles.font__size__14}`}>
          Trained, Verified and Background Checked
        </p>
      ) : (
        <p className={`text-muted text-center mb-4 ${styles.font__size__14}`}>
          Pro{' '}
          {props?.level === 'Bartender' ? (
            <Link href="/bartender/top-rated-bartenders">
              <a target="_blank">Bartenders</a>
            </Link>
          ) : props?.level === 'Waiter' ? (
            <Link href="/waiter/top-rated-waiters">
              <a target="_blank">Waiters</a>
            </Link>
          ) : props?.level === 'Cleaner' ? (
            <Link href="/cleaner/top-rated-cleaners">
              <a target="_blank">Cleaners</a>
            </Link>
          ) : (
            <Link href="/cook/top-rated-cooks-chefs">
              <a target="_blank">cooks and chefs</a>
            </Link>
          )}{' '}
          that ensure{' '}
          {props?.level === 'Bartender'
            ? 'a memorable event'
            : props?.level === 'Waiter'
            ? 'a memorable event'
            : props?.level === 'Cleaner'
            ? 'a hassle free event'
            : 'a lip-smacking event'}
          .
        </p>
      )}
      <div className={styles.OuterWrap}>
        <FaChevronLeft className={styles.PrevBtn} onClick={goPrev} />
        <div className={styles.Swiper}>
          <Swiper ref={ref} {...params} pagination={{ clickable: true }}>
            {topCooks?.map((cook: Icook) => (
              <div className="swiper-slide" key={cook.cook_id}>
                <CookCardItem {...cook} />
              </div>
            ))}
          </Swiper>
        </div>
        <FaChevronRight className={styles.NextBtn} onClick={goNext} />
      </div>
      <Row>
        <Col className="text-center">
          {props?.level === 'Bartender' ? (
            <Link href="/bartender/top-rated-bartenders">
              <a className={`btn ${styles.learn__more__btn}`} target="_blank">
                View More
              </a>
            </Link>
          ) : props?.level === 'Waiter' ? (
            <Link href="/waiter/top-rated-waiters">
              <a className={`btn ${styles.learn__more__btn}`} target="_blank">
                View More
              </a>
            </Link>
          ) : props?.level === 'Cleaner' ? (
            <Link href="/cleaner/top-rated-cleaners">
              <a className={`btn ${styles.learn__more__btn}`} target="_blank">
                View More
              </a>
            </Link>
          ) : (
            <Link href="/cook/top-rated-cooks-chefs">
              <a className={`btn ${styles.learn__more__btn}`} target="_blank">
                View More
              </a>
            </Link>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default TopRatedChefs
